<template>
  <div class="listitem" v-on:click="returnHandler" :class="layout">
    <div v-if="icon" class="listitem__icon">
      <img :src="icon" width="40" />
    </div>
    <div class="listitem__text">
      {{ label }}
    </div>
    <div class="listitem__action">
      <switches
        v-if="type == 'switch'"
        v-model="model"
        :theme="config.theme"
        :type-bold="config.type_bold"
        :color="config.color"
      ></switches>
    </div>
  </div>
</template>

<script>
import Switches from "vue-switches";

export default {
  components: {
    Switches,
  },
  name: "ListItem",
  props: {
    icon: null,
    label: null,
    clickHandler: null,
    type: null,
    config: null,
    layout: null,
    value: null,
  },
  data: function () {
    return {
      model: this.value,
    };
  },
  watch: {
    value() {
      this.model = this.value;
    },
  },
  methods: {
    returnHandler: function () {
      if (this.type == "switch") {
        this.model = !this.model;
        this.$emit("input", this.model);
        if (this.clickHandler) {
          this.clickHandler(this.model, this);
        }
      } else {
        if (this.clickHandler) {
          this.clickHandler(this);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";

.vue-switcher {
  pointer-events: none;
}
</style>
